import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, CircularProgress, FormControlLabel, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { SpotSelect } from '../../components/SpotSelect.components';
import { useDomainPath } from '../../auth/auth.context';
import { get } from '../../fetch';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';

interface SpotsInputProps {
  value?: CRM.ActivitySpots;
  onChange: (value: Partial<CRM.ActivitySpots>) => any;
}

export function SpotsInput({ onChange, value }: SpotsInputProps) {
  const path = useDomainPath('/activity-spot');
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);
  // const [arrivalSpot, setArrivalSpot] = useState<CRM.ActivitySpot>();
  // const [departureSpot, setDepartureSpot] = useState<CRM.ActivitySpot>();

  const loadSpots = useCallback(() => {
    get<CRM.ActivitySpotDocument[]>(path).then(setSpots);
  }, [setSpots, path]);

  useEffect(loadSpots, [loadSpots]);

  if (isEmpty(spots)) {
    return <CircularProgress size="1em" />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <SpotSelect
          onlyDepartures
          label={'Départ'}
          value={value?.departureId}
          spots={spots}
          onChange={spot =>
            onChange({ ...value, departureId: spot && spot._id })
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SpotSelect
          onlyArrivals
          label={'Arrivée'}
          value={value?.arrivalId}
          spots={spots}
          onChange={spot => onChange({ ...value, arrivalId: spot && spot._id })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    onChange={event => {
                      onChange({ ...value, isNoReturnTransport: event.target.checked });
                    }}
                    checked={value?.isNoReturnTransport || false}
                  />
                }
                label={'Pas de retour'}
                labelPlacement="end"
        />
        &nbsp;
        <DirectionsBusIcon 
          style={{marginBottom:"-8px"}} 
          className={'iconBlack'} 
          titleAccess={'Jours de Transport'} 
        />
      </Grid>
    </Grid>
  );
}
