import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  makeStyles,
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  canoeEquipementOf,
  countBookingPeople,
  fixedDayjs,
  labelOfArrival,
  numberOrZero,
} from '@crm/utils';
import { CrmTableRow, SmallTableCell } from './Table.component';
import { PositiveNumberField } from './fields/PositiveNumberField.component';
import { Dayjs } from 'dayjs';
import { get, post, put } from '../fetch';
import { useDomainPath } from '../auth/auth.context';

const useStyles = makeStyles((theme: Theme) => ({
  cellNoPadding: {
    padding:'0'
  },
  cellPaddingLeft: {
    padding: '0 0 4px 16px'
  },
  cellTopPadding: {
    padding: '0.5rem 0 0 0'
  }
}));

interface Props {
  reservations: CRM.ReservationOverview[];
  dateKey: 'activityArrivalDate' | 'activityDate';
  date: Dayjs;
  spotId: string;
  isArrivalDetails?: boolean;
}

interface Details {
  people: number;
  c2: number;
  c3: number;
  k1: number;
}

type DetailsByArrivalLabel = CRM.Dictionary<Details>;

function mergeDetails(
  detail: Details,
  reservation: CRM.ReservationOverview,
  isArrivalDetails?: boolean
): Details {
  const people =
    (isArrivalDetails && reservation.options?.peopleAtArrivalCount !== undefined
      && reservation.options?.peopleAtArrivalCount !== null
      && reservation.options?.peopleAtArrivalCount >= 0) ?
      reservation.options?.peopleAtArrivalCount
    : countBookingPeople(reservation.people, reservation.options.instructors);
  const c2 = canoeEquipementOf('c2', reservation.options);
  const c3 = canoeEquipementOf('c3', reservation.options);
  const k1 = canoeEquipementOf('k1', reservation.options);

  return {
    people: (detail?.people || 0) + people,
    c2: (detail?.c2 || 0) + numberOrZero(c2),
    c3: (detail?.c3 || 0) + numberOrZero(c3),
    k1: (detail?.k1 || 0) + numberOrZero(k1),
  };
}

export const DayDetailsTable: React.FC<Props> = ({ dateKey, reservations, isArrivalDetails, date, spotId }) => {

  const classes = useStyles();
  const defaultBoatStock: CRM.BoatStockDocument = useMemo(() => {
    return {
      _id: '',
      date: fixedDayjs(date.toDate().toISOString()).endOf('day').toDate(),
      spotId: spotId,
      c2InitialStock: 0,
      k1InitialStock: 0,
      c2FinalStock: -1,
      k1FinalStock: -1
    }
  },[
    date,
    spotId,
  ]);
  const [ boatStock, setBoatStock ] = useState<CRM.BoatStockDocument>(defaultBoatStock);
  const boatStockPath = useDomainPath('/boat-stock');

  //#TODO function who calls api for updating boatstock mongoose,
  //and setBoatStock
  const createOrUpdateBoatStock = useCallback((value:Partial<CRM.BoatStock>) => {

    const newBoatStockDocument = {...boatStock, ...value};
    const {_id, ...newBoatStock} = newBoatStockDocument;
    if(boatStock._id !== '') {
      put<CRM.BoatStock>(boatStockPath+'/'+boatStock._id, 
          newBoatStock as CRM.BoatStock
        ).then(() => {
        //setBoatStock(newBoatStockDocument);
        refreshBoatStock();
      });
    } else {
      post<CRM.BoatStock>(boatStockPath, 
          newBoatStock as CRM.BoatStock
        ).then((result: CRM.BoatStockDocument) => {
          if(result._id) {
            //setBoatStock(result);
            refreshBoatStock();
          }
      });
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    boatStockPath,
    boatStock
  ]);

  const dayDetails = useMemo(() => {

    return reservations.reduce((details, reservation) => {
      const label = labelOfArrival(reservation[dateKey]);
      details[label] = mergeDetails(details[label], reservation, isArrivalDetails);
      return details;
    }, {} as DetailsByArrivalLabel);
  }, [reservations, dateKey, isArrivalDetails]);


  const dayTotal = useMemo(() => {
    return Object.values(dayDetails).reduce(
      (total, arrivalDetails) => {
        total.people += arrivalDetails.people;
        total.c2 += arrivalDetails.c2;
        total.c3 += arrivalDetails.c3;
        total.k1 += arrivalDetails.k1;
        return total;
      },
      { people: 0, c2: 0, c3: 0, k1: 0 } as Details,
    );
  }, [dayDetails]);

  const refreshBoatStock = useCallback(() => {
    get<CRM.BoatStockDocument>(
      boatStockPath+'/by/dateSpot?date='+date.format('YYYYMMDD') +
      '&spotId=' + spotId
      ).then((boatStockResult) => {
        if(boatStockResult && boatStockResult._id) {
          setBoatStock(boatStockResult);
        } else {
          setBoatStock({
            ...defaultBoatStock, 
            c2InitialStock: dayTotal.c2, 
            k1InitialStock: dayTotal.k1
          });
        }
      });
  }, [
    spotId,
    date,
    boatStockPath,
    dayTotal,
    defaultBoatStock
  ]);

  useEffect(() => {
    refreshBoatStock();
  },[
    refreshBoatStock
  ]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
                <TableCell className={classes.cellTopPadding} colSpan={2} align="center">Stock initial :</TableCell>
                <TableCell className={classes.cellPaddingLeft} align="center">
                  <PositiveNumberField
                    label="C2"
                    variant="standard"
                    value={boatStock.c2InitialStock}
                    minValue={dayTotal.c2}
                    onChange={(el)=> {createOrUpdateBoatStock({c2InitialStock: Number(el.target.value)})}}
                    fullWidth={true}
                    required
                  />
                </TableCell>
                <TableCell className={classes.cellNoPadding} align="center"></TableCell>
                <TableCell className={classes.cellPaddingLeft} align="center">
                  <PositiveNumberField
                    label="K1"
                    variant="standard"
                    value={boatStock?.k1InitialStock}
                    minValue={dayTotal.k1}
                    onChange={(el)=> {createOrUpdateBoatStock({k1InitialStock: Number(el.target.value)})}}
                    fullWidth={true}
                    required
                  />
                </TableCell>
            </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <SmallTableCell align="center">Horaire</SmallTableCell>
            <SmallTableCell align="center">Personnes</SmallTableCell>
            <SmallTableCell align="center">C2</SmallTableCell>
            <SmallTableCell align="center">C3</SmallTableCell>
            <SmallTableCell align="center">K1</SmallTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(dayDetails).map(([label, details]) => (
            <CrmTableRow key={label}>
              <SmallTableCell align="center">{label}</SmallTableCell>
              <SmallTableCell align="center">{details.people}</SmallTableCell>
              <SmallTableCell align="center">{details.c2}</SmallTableCell>
              <SmallTableCell align="center">{details.c3}</SmallTableCell>
              <SmallTableCell align="center">{details.k1}</SmallTableCell>
            </CrmTableRow>
          ))}
          <CrmTableRow>
            <SmallTableCell align="center">
              <b>Total</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.people}</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.c2}</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.c3}</b>
            </SmallTableCell>
            <SmallTableCell align="center">
              <b>{dayTotal.k1}</b>
            </SmallTableCell>
          </CrmTableRow>
        </TableBody>
        <TableBody>
          <TableRow>
                <TableCell  className={classes.cellTopPadding} colSpan={2} align="center">Stock fin :</TableCell>
                <TableCell className={classes.cellPaddingLeft} align="center">
                  <PositiveNumberField
                    label="C2"
                    variant="standard"
                    value={boatStock.c2FinalStock > -1 ? boatStock.c2FinalStock : (boatStock.c2InitialStock-dayTotal.c2)}
                    minValue={-1}
                    onChange={(el)=> {createOrUpdateBoatStock({c2FinalStock: Number(el.target.value)})}}
                    fullWidth={true}
                    required
                  />
                </TableCell>
                <TableCell className={classes.cellNoPadding} align="center"></TableCell>
                <TableCell className={classes.cellPaddingLeft} align="center">
                  <PositiveNumberField
                      label="C2"
                      variant="standard"
                      value={boatStock.k1FinalStock > -1 ? boatStock.k1FinalStock : (boatStock.k1InitialStock-dayTotal.k1)}
                      minValue={-1}
                      onChange={(el)=> {createOrUpdateBoatStock({k1FinalStock: Number(el.target.value)})}}
                      fullWidth={true}
                      required
                    />
                </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
