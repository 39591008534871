import React, { useCallback, useEffect, useState } from "react";
import { useDomainPath } from "../auth/auth.context";
import { isEmpty, sortBy } from "lodash";
import { get, post } from "../fetch";
import { Box, CircularProgress, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import { SpotStrip } from "../components/SpotStrip.component";
import { BusPrioritySelect } from "../components/selects/BusPrioritySelect.component";
import dayjs from 'dayjs';
import { SpotTimingsFilter } from "../components/filters/spotTimingsFilter.component";
import { fixedDayjs } from "@crm/utils";
import { RichGrid } from "../components/table/RichGrid.component";
import { busUnFilledPassengersTableProvider } from "../reservations/reservationGrid.columns";
import { RowHandlers } from "../components/table/Table";
import { Theme as DefaultTheme, Theme } from '@material-ui/core/styles/createMuiTheme';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { useAlerts } from "../hooks/useAlerts";

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
  rootSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['400'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.8, 0.5),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  }
}));

export function DriverArrivalsView() {
  
  //const auth = useAuth();
  const { notify, handleClose } = useAlerts();
  //const { loggedUser } = useAuthUsers();
  const spotPath = useDomainPath('/activity-spot');
  const busTravelPath = useDomainPath('/busTravel');
  const reservationPath = useDomainPath('/reservation');
  const [selectedSpot, setSelectedSpot] = useState<CRM.ActivitySpotDocument>();
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);
  const [busTravelsArrival, setBusTravelsArrival] = useState<CRM.BusTravelDocument[]>();
  //const [busTravelsSpotIds, setBusTravelsSpotIds] = useState<string[]>([]);
  const [filteredTravelSpotTimings, setFilteredTravelSpotTimings] = useState<CRM.SpotTimingsOccurence[]>([]);
  const [filteredTravelSpotTimingsValues, setFilteredTravelSpotTimingsValues] = useState<string[]>([]);
  const [selectedTravelSpotTiming, setSelectedTravelSpotTiming] = useState<CRM.SpotTimingsOccurence | undefined>(undefined);
  const [unfilledReservations, setUnfilledReservations] = useState<CRM.ReservationDetailed[]>([]);
  //const [driverUserId, setDriverUserId] = useState<string>();
  const [refresh, setRefresh] = useState<number>(1);
  const today: dayjs.Dayjs = fixedDayjs(Date()).add(0,'day');//add negative days for test with previous days
  const [domains, setDomains] = useState<CRM.Dictionary<string>>({});
  const classesgrid: ClassNameMap<string> = useStylesGrid();
  const defaultPriorityBusTravel = {
    busTravelId: '',
    busId: '',
    busName: '',
    busStatus: "ready" as CRM.BusStatus,
    hour: 23,
    minute: 59
  }
  const [priorityBusTravel, setPriorityBusTravel] = useState<CRM.BusTravelPriorityParams>(
    defaultPriorityBusTravel
  );

  useEffect(() => {
    get<CRM.EntityName[]>(`domains`).then(domains => {
      setDomains(
        domains.reduce((acc, domain) => {
          acc[domain._id] = domain.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setDomains]);

  function checkOutEvent(reservationId: string, status: CRM.ReservationStatus) {
    if(
      priorityBusTravel.busTravelId !== '' && 
      priorityBusTravel.busId !== '' && 
      status === "done"
      ) {
      //'/fillBusTravelManualCheckOut/:busId/:reservationId'
      const isNoBusRotation: boolean = (
        !priorityBusTravel.inRotationTime || (
          priorityBusTravel.inRotationTime && 
          priorityBusTravel.hour+''+priorityBusTravel.minute !== 
          priorityBusTravel.inRotationTime.hour+''+priorityBusTravel.inRotationTime.minute
      ));
      const status: CRM.ReservationStatus = isNoBusRotation ? "done" : "finalize";
      try {
        //#TODO add hour and minutes parameter to checkout endpoint for updating reservation arrivalDate !
        post(
          reservationPath+'/travelReservations/checkOut/'+priorityBusTravel.busId+'/'+ reservationId+"/"+status, 
          {hour: priorityBusTravel.hour, minute: priorityBusTravel.minute} as CRM.Time
        ).then(() => {
          setRefresh(refresh+1);
          refreshUnfilledReservations();
          //loadTravelsArrival();
          notify(
            `Passagers ajoutés à ` + priorityBusTravel.busName,
            'success',
            () => {
              revertCheckOut(reservationId).then(() => { handleClose();})
            }
          );
        });
      } catch(err) {
        notify(`Impossible d'ajouter les passagers`, 'error');
      }
    } else if(priorityBusTravel.busTravelId === '') {
      notify(`Veuillez sélectionner un transport`, 'warning');
    }

  }

  const revertCheckOut = async (reservationId: string): Promise<boolean> => {
    try {
      await post(reservationPath+'/travelReservations/revertCheckOut/'+ reservationId);
      setRefresh(refresh+2);
      refreshUnfilledReservations();
      //loadTravelsArrival();
      return true;
    } catch(err) {
      return false;
    }
  }

  function isArrivalSpot(spot: CRM.ActivitySpotDocument) {
    return spot.arrivals && !isEmpty(spot.arrivals.every);
  }

  const changeSelectedSpot = useCallback((spot: CRM.ActivitySpotDocument) => {
    setSelectedSpot(spot);

    const busTravelSpotTimings: CRM.SpotTimingsOccurence[] = ((busTravelsArrival && spot) && busTravelsArrival?.map(
      (busTravel) => {
        return busTravel.travelSpotsTimings.find(ts => ts.spotId.includes(spot._id))?.timings || [];
      }
      ).flat()) || [];
      //console.log(busTravelSpotTimings);
      let ftsValues: string[] = [];

      const spotTimingsUnique = [
        ...busTravelSpotTimings
          .reduce((spotTimingUnique, curr) => {
            //console.log(spotTimingUnique, curr)
            ftsValues = ftsValues.filter(
              f => f!== ''+curr.hour+curr.minute
              ).concat(
                [''+curr.hour+curr.minute]
            );
            if (!spotTimingUnique.has(''+curr.hour+curr.minute)) {
              spotTimingUnique.set(''+curr.hour+curr.minute, curr);
            }
            return spotTimingUnique;
          }, new Map())
          .values()
      ];
      setFilteredTravelSpotTimingsValues(ftsValues);
      setFilteredTravelSpotTimings(spotTimingsUnique.concat([{hour:0, minute:0, allowOverBooking: false, capacity:0}]));
      setRefresh(refresh+1);
      refreshUnfilledReservations(spot._id);
      setPriorityBusTravel(defaultPriorityBusTravel);
      // #TODO fix eventually these dependancies for not causes infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[busTravelsArrival]);

  const loadSpots = useCallback(() => {
    get<CRM.ActivitySpotDocument[]>(spotPath).then(result => {
      const availableSpots = sortBy(
        result.filter(isArrivalSpot)
          /*.filter(s => busTravelsSpotIds
          .includes(s._id))*/,//Commented because all allowed drivers can view all spotIds
        spot => spot.displayName,
      );
      setSpots(availableSpots);
      if (!selectedSpot && availableSpots.length > 0) {
        changeSelectedSpot(availableSpots[0]);
      }
    });
  }, [spotPath, selectedSpot, changeSelectedSpot /*busTravelsSpotIds*/]);

  const loadTravelsArrival = useCallback(() => {
    get<CRM.BusTravelDocument[]>(
      //busTravelPath+'/arrivalsForDriverId/'+(auth.userGroup?.role === 'driver' ? loggedUser?._id : driverUserId)
      busTravelPath+'/arrivals/'
      ).then(busTravels => {
        setBusTravelsArrival(busTravels);
        /*const _busTravelSpotIds = busTravels.map(
          (busTravel) => {
            return busTravel.travelSpotsTimings.map(({spotId}) => spotId);
          }
          ).flat();
        setBusTravelsSpotIds(_busTravelSpotIds);*/
    });
  },[
    busTravelPath,
    //auth.userGroup?.role, 
    //loggedUser?._id, 
    //driverUserId
  ]);

  const refreshUnfilledReservations = useCallback((spotId?: string)=> {
    get<CRM.ReservationDetailed[]>(
      `${reservationPath}/travelReservations/dayEmptyBusReservations?`+
      'day='+today.format('YYYYMMDD') +
      '&spotId='+(spotId || selectedSpot?._id) +
      '&direction=return'+
      '&realTime=true').then((reservations) => {
        if(selectedTravelSpotTiming) {
          setUnfilledReservations(
            reservations.filter(
              r => dayjs(r.activityArrivalDate).hour() === selectedTravelSpotTiming.hour &&
              dayjs(r.activityArrivalDate).minute() === selectedTravelSpotTiming.minute &&
              r.status !== "done" && r.status !== "finalize"
            )
          )
        } else {
          setUnfilledReservations(reservations);
        }
      });
  },[
    reservationPath,
    today,
    selectedSpot,
    selectedTravelSpotTiming
  ]);

  useEffect(() => {
    if(refresh) {
      loadTravelsArrival();
    }
  }
    , [loadTravelsArrival, refresh]);
  useEffect(loadSpots, [loadSpots]);

  //set drivers list for admin selection
  //set available timingOccurences
  //set buses to fill with buttons (lists view, actions)
  //add unfilled reservations (call /filledOrEmptyBusReservations endpoint)
  //manage responsive ergonomy (tablets++)

  if (!selectedSpot) {
    return <div><CircularProgress size="1em" /> Bientôt disponible pour les administrateurs</div>;
  }
  
  return (
    <div>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        pb={1}
      >
        <Grid container xs={12} spacing={2}>
        <Grid item xs={1} sm={1} lg={1}/>
        <Grid item xs={2} sm={3} lg={2}>
          <SpotStrip
            selectedId={selectedSpot._id}
            onChange={(spot)=> {changeSelectedSpot(spot);}}
            spots={spots}
          />
        </Grid>
        <Grid item xs={9} sm={8} lg={9}>
        <SpotTimingsFilter
        timings={ {every: filteredTravelSpotTimings.filter(ts => ts.hour !== 0)} }
        includingOccurrence={selectedTravelSpotTiming}
        onChange={(spotTimingOccurence)=> { 
          setSelectedTravelSpotTiming(spotTimingOccurence);
          setRefresh(refresh+1);
          setPriorityBusTravel(defaultPriorityBusTravel);
        }}
        />
        </Grid>
        </Grid>
      </Box>
      <Divider />
      <br/>
      <Box>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={3} sm={4} lg={3}>
          <Grid container spacing={2} xs={12}>
              <Grid item>
                <Typography variant="subtitle1">
                  &nbsp;
                </Typography>
              </Grid>
            </Grid>
          <Box style={{height:'80vh',overflowY:'scroll'}}>
            {selectedTravelSpotTiming && (
              <BusPrioritySelect
                spot={selectedSpot || spots.find(s => s.displayName === "Vallon Pont-d'Arc")}
                direction="return"
                day={today}
                spotTimingOccurence={selectedTravelSpotTiming}
                refresh={refresh}
                onUpdate={()=> setRefresh(refresh+1)}
                forcePriorityBusTravel={priorityBusTravel}
                setForcePriorityBusTravel={setPriorityBusTravel}
              />
            )
            /*<BusPrioritySelect
              spot={spots.find(s => s.displayName === "Vallon Pont-d'Arc") || selectedSpot}
              direction="departure"
              day={dayjs("2024/10/03")}
              spotTimingOccurence={{hour: 9, minute: 0, capacity:0, allowOverBooking: true}}
              refresh={refresh}
            />*/}
            {!selectedTravelSpotTiming && filteredTravelSpotTimings.filter(s => s.hour !== 0).map((spotTiming) => {
              return (
                <Box mb={2}>
                  <BusPrioritySelect
                    spot={selectedSpot || spots.find(s => s.displayName === "Vallon Pont-d'Arc")}
                    direction="return"
                    day={today}
                    spotTimingOccurence={spotTiming}
                    refresh={refresh}
                    onUpdate={()=> setRefresh(refresh+1)}
                    forcePriorityBusTravel={priorityBusTravel}
                    setForcePriorityBusTravel={setPriorityBusTravel}
                  />
                </Box>
              );
            })}
            </Box>
          </Grid>
          <Grid item xs={9} sm={8} lg={9}>
            <Grid container spacing={2} xs={12}>
              <Grid item>
                <Typography variant="subtitle1">
                  CLIENTS
                </Typography>
              </Grid>
            </Grid>
            <Box style={{height:'80vh',overflowY:'scroll', paddingRight:'2rem'}}>
            {
            filteredTravelSpotTimings.map((spotTiming) => {
              const spotTimingReservations = unfilledReservations.filter(
                r => spotTiming.hour !== 0 ? (r.activityArrivalDate && 
                     fixedDayjs(r.activityArrivalDate).hour() === spotTiming.hour && 
                     fixedDayjs(r.activityArrivalDate).minute() === spotTiming.minute) : (
                      !r.activityArrivalDate || (r.activityArrivalDate && 
                      !filteredTravelSpotTimingsValues.includes(
                        ''+fixedDayjs(r.activityArrivalDate).hour()+fixedDayjs(r.activityArrivalDate).minute()
                        )
                      )
                     )
              );
              //console.log(filteredTravelSpotTimingsValues);
              /*console.log(spotTiming)
              if(unfilledReservations.length > 0) {
                unfilledReservations[0].activityArrivalDate && 
                console.log(fixedDayjs(unfilledReservations[0].activityArrivalDate).hour())
              }*/
              if(spotTimingReservations.length === 0) {
                return(<></>);
              }
              return (
                <Box mb={0} display="flex" flexDirection="row">
                  <Grid item xs={12}>
                    <h4>
                      {spotTiming.hour !== 0 && <b>{spotTiming.hour}h{spotTiming.minute === 0 ? '00' : spotTiming.minute}</b>}
                      {spotTiming.hour === 0 && <b>SANS HORAIRE DE RETOUR</b>}
                    </h4>
                  <RichGrid
                    style={{
                      paddingRight: '10px', //compensate for scrollbar width
                    }}
                    gridStyle={{}}
                    columns={busUnFilledPassengersTableProvider(domains, checkOutEvent).columns}
                    rows={spotTimingReservations}
                    renderRow={reservation => {
                      return (
                        <div className='Grid-row'>
                          <Grid
                            container
                            className={classesgrid.root}
                            spacing={1}
                          >
                            {busUnFilledPassengersTableProvider(domains, checkOutEvent).columns.map(column => {
                              const Cell = column.Cell;
                              return (
                                <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                                  {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                                  {column.renderCell &&
                                    column.renderCell(reservation, {} as RowHandlers)}
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      );
                    }}
                  />
                  </Grid>
                </Box>
              )
            })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}