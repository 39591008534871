import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Theme,
} from '@material-ui/core';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty, sortBy } from 'lodash';
// import FileSaver from 'file-saver';
import dayjs, { Dayjs } from 'dayjs';
import { get, post } from '../fetch';
import { useDomainPath } from '../auth/auth.context';
//import { ArrivalGridItem } from './arrivalGridItem.component';
import { SpotStrip } from '../components/SpotStrip.component';
import { DomainsFilter } from '../components/filters/domainsFilter.component';
import { SpotMultiTimingsFilter } from '../components/filters/spotTimingsFilter.component';
//import { ArrivalGridHeader } from './arrivalGridHeader.component';
import { DayDetailsTable } from '../components/DayDetailsTable.component';
import { StatusFilter } from '../components/filters/statusFilter.component';
import { RichGrid } from '../components/table/RichGrid.component';
import { Grid } from '@material-ui/core';
import {
  arrivalsTableProvider
} from '../reservations/reservationGrid.columns';
import { compareDate, RowHandlers } from '../components/table/Table';
import { ClassNameMap } from "@material-ui/styles";
import { useHistory } from 'react-router-dom';
import { OneDayStripSelect } from '../components/oneDayStripSelect.component';
import { groupTimeSlotsColumns } from '@crm/utils';
import { KindFilter } from '../components/filters/kindFilter.component';
import { TimeSlotsDialog } from '../activities/create/timeslots/timeSlotsDialog.component';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
    },
  },
  card: {
    margin: theme.spacing(1),

    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  media: {
    height: 140,
  },
}));

const useStylesGrid = makeStyles<
  DefaultTheme
>((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['300'],
    borderRadius: '5px',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.3, 0),
    cursor: 'pointer',
    transition: 'box-shadow 0.3s',

    '&:hover': {
      boxShadow: theme.shadows['4'],
    },
  },
}));

function isArrivalSpot(spot: CRM.ActivitySpotDocument) {
  return spot.arrivals && !isEmpty(spot.arrivals.every);
}

const inDomains = (
  allDomains: CRM.Dictionary<string>,
  includingDomainIds: string[],
) => {
  if (Object.keys(allDomains).length === includingDomainIds.length) {
    return () => true;
  }
  return (res: CRM.ReservationOverview) =>
  includingDomainIds.includes(res.domain);
};

const inStatus = (status: string[]) => {
  return (res: CRM.ReservationOverview) => status.length === 0 ? res : status.includes(res.status);
};

const inOccurrences = (includingTimings: CRM.SpotTimingsOccurence[]) => {
  return (res: CRM.ReservationOverview) => {
    if (!res.activityArrivalDate) {
      return true;
    }
    if (includingTimings?.length === 0) {
      return false;
    }
    const day = dayjs(res.activityArrivalDate);
    return includingTimings.some(
      timing => timing.minute === day.minute() && timing.hour === day.hour(),
    );
  };
};

const inKinds = (kinds: CRM.ActivityKind[]) => {
  return (res: CRM.ReservationOverview) => {
    if(
      (kinds.includes('canoe') && kinds.includes('supervised')) ||
      (kinds.includes('canoe') && !res.activityTimeSlot) ||
      (kinds.includes('supervised') && res.activityTimeSlot)
    ) {
      return true;
    }
    return false;
  }
   
};

export function ArrivalsView() {
  const history = useHistory();
  const classes = useStyles();
  const spotPath = useDomainPath('/activity-spot');
  const arrivalPath = useDomainPath('/arrival');
  const timeSlotPath = useDomainPath(`/activity-timeslots`);
  const classesgrid: ClassNameMap<string> = useStylesGrid();

  const [filterDay, setFilterDay] = useState<Dayjs>(dayjs());
  const [selectedSpot, setSelectedSpot] = useState<CRM.ActivitySpotDocument>();
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);
  const [domains, setDomains] = useState<CRM.Dictionary<string>>({});
  const [includingDomainIds, setIncludingDomainIds] = useState<string[]>([]);
  const [includingStatus, setIncludingStatus] = useState<
    CRM.ReservationStatus[]
  >(['booked']);
  const [includingSpotOccurrences, setIncludingSpotOccurrences] = useState<
    CRM.SpotTimingsOccurence[]
  >([]);
  const [includingKinds, setIncludingKinds] = useState<CRM.ActivityKind[]>(['canoe','supervised']);
  const [reservations, setReservations] = useState<CRM.ReservationOverview[]>(
    [],
  );
  const [completeFilteredReservations, setCompleteFilteredReservations] = useState<CRM.ReservationOverview[]>(
    [],
  );

  const updateReservation = useCallback((reservationId) => () => {
    history.push(`/reservations/${reservationId}`);
  }, [history]);
  const [timeSlotReservationsViewDialogOpen, setTimeSlotReservationsViewDialogOpen] = useState(false);
  const [selectedTimeSlotReservations, setSelectedTimeSlotReservations] = useState<CRM.ReservationDetailed[]>([]);
  //const [groups, setGroups] = useState<CRM.Dictionary<string>>({});

  const timeSlotReservationsViewDialog = useCallback((activity?: string, id?: string, doNotOpenDialog?:boolean) => {
      get<CRM.ReservationDetailed[]>(`${timeSlotPath}/reservations/${activity || ''}/${id || ''}?omitInstructors=true`).then((reservations) => {
        setSelectedTimeSlotReservations(reservations);
        if(doNotOpenDialog === undefined || doNotOpenDialog === false) {
          setTimeSlotReservationsViewDialogOpen(true);
        }
      }
      )
    
  }
    , [
      setSelectedTimeSlotReservations,
      setTimeSlotReservationsViewDialogOpen,
      timeSlotPath
    ]
  );

  const timeSlotReservationsViewDialogClose = useCallback(
    () => setTimeSlotReservationsViewDialogOpen(false),
    [setTimeSlotReservationsViewDialogOpen],
  );

  const filteredReservations = useMemo(
    () => {
      const _reservations = reservations
        .filter(inDomains(domains, includingDomainIds))
        .filter(inStatus(includingStatus))
        .filter(inOccurrences(includingSpotOccurrences))
        .filter(inKinds(includingKinds));
        setCompleteFilteredReservations(_reservations);
        //.sort((a,b) => compareDate(a.activityArrivalDate, b.activityArrivalDate, true));

        console.log(_reservations)
        const result = groupTimeSlotsColumns(_reservations);
        console.log(result)
        if(result && result.length > 0) {
          return _reservations
          .filter(
            r => r.activityTimeSlot === undefined || 
            r.activityTimeSlot === null
          ).concat(result)
          .sort((a,b) => compareDate(a.activityArrivalDate, b.activityArrivalDate, true));
        }

        return _reservations
        .sort((a,b) => compareDate(a.activityArrivalDate, b.activityArrivalDate, true));
    },
    [
      domains,
      reservations,
      includingDomainIds,
      includingStatus,
      includingSpotOccurrences,
      includingKinds
    ],
  );

  const printArrivalList = useCallback(() => {
    if (completeFilteredReservations?.length === 0) {
      return;
    }
    post<CRM.ValidateArrivalsSubmit>(arrivalPath, {
      spotDisplayName: selectedSpot?.displayName || 'Inconnu',
      reservations: completeFilteredReservations.map(r => r._id),
    }).then(result => {
      var win = window.open('', '_blank');
      if(win) {
        win.document.body.innerHTML = '<body onload=`window.print()`><div style="margin-top:20px;">'+result+'</div></body>';
        win.print();
      }
    });
  }, [arrivalPath, selectedSpot, completeFilteredReservations]);

  const loadSpots = useCallback(() => {
    get<CRM.ActivitySpotDocument[]>(spotPath).then(result => {
      const availableSpots = sortBy(
        result.filter(isArrivalSpot),
        spot => spot.displayName,
      );
      setSpots(availableSpots);
      if (!selectedSpot && availableSpots.length > 0) {
        setSelectedSpot(availableSpots[0]);
      }
    });
  }, [spotPath, selectedSpot]);

  const refreshReservations = useCallback(() => {
    if (!selectedSpot) {
      return;
    }

    const start = filterDay.format('YYYYMMDD');
    const end = filterDay.format('YYYYMMDD');
    get<CRM.ReservationOverview[]>(
      `${arrivalPath}?start=${start}&end=${end}&spot=${selectedSpot._id}`,
    )
      //.then(result => sortBy(result, resa => resa.activityDate))
      .then(result => {
        get<CRM.ReservationOverview[]>(
          `${arrivalPath}/ontwodays?start=${start}&end=${end}&spot=${selectedSpot._id}&day=1`,
        )
          .then(resultD1 => {
            result = result.concat(resultD1);
            get<CRM.ReservationOverview[]>(
              `${arrivalPath}/ontwodays?start=${start}&end=${end}&spot=${selectedSpot._id}&day=2`,
            )
              .then(resultD2 => {
                result = result.concat(resultD2)
                  .sort((a, b) => compareDate(a.activityDate, b.activityDate));
                setReservations(result);
              });
          });
      });
  }, [arrivalPath, filterDay, selectedSpot]);

  const changeIncludingSpotOccurrences = useCallback(
    (occurrences: CRM.SpotTimingsOccurence[]) => {
      setIncludingSpotOccurrences(occurrences);
    },
    [],
  );

  useEffect(() => {
    get<CRM.EntityName[]>(`domains`).then(domains => {
      setDomains(
        domains.reduce((acc, domain) => {
          acc[domain._id] = domain.displayName;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
      setIncludingDomainIds(domains.map(domain => domain._id));
    });
  }, [setDomains, setIncludingDomainIds]);

  /**
   * Select first timing on spot change
   */
  useEffect(() => {
    if (selectedSpot && selectedSpot.arrivals?.every) {
      setIncludingSpotOccurrences(selectedSpot.arrivals!.every);
    }
  }, [selectedSpot]);

  useEffect(refreshReservations, [
    refreshReservations,
    filterDay,
    selectedSpot,
  ]);
  useEffect(loadSpots, [loadSpots]);

  if (!selectedSpot) {
    return <CircularProgress size="1em" />;
  }

  return (
    <div className={classes.root}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
       <OneDayStripSelect
          onChange={setFilterDay}
          value={filterDay}
        />
      </Box>
      <Divider />
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <SpotStrip
          selectedId={selectedSpot._id}
          onChange={setSelectedSpot}
          spots={spots}
        />
      </Box>
      <Divider />
      <Box display={'flex'} justifyContent={'space-between'} flexGrow={0.85}>
        <Box flexGrow={0.9}>
          {<RichGrid
            columns={arrivalsTableProvider(domains).columns}
            rows={filteredReservations}
            gridStyle={{
              maxHeight:'65vh',
              marginBottom:'-7vh',
              overflowY:'scroll',
              overflowX:'hidden',
            }}
            renderRow={reservation => {
              return (
                <div className='Grid-row'>
                  <Grid
                    container
                    className={classesgrid && classesgrid.root}
                    spacing={1}
                    onClick={reservation.activityTimeSlot ? undefined : updateReservation(reservation._id)}
                  >
                    {arrivalsTableProvider(
                      domains,
                      reservation.activityTimeSlot ? timeSlotReservationsViewDialog : undefined
                      ).columns.map(column => {
                      const Cell = column.Cell;
                      return (
                        <Grid item key={column.key} xs={column.xs} className={'ellipsis'}>
                          {Cell && <Cell value={reservation} handlers={{} as RowHandlers} />}
                          {column.renderCell &&
                            column.renderCell(reservation, {} as RowHandlers)}
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              );
            }}
          />}
        </Box>
        <Box display={'flex'} flexDirection={'column'} width={400}>
          <DayDetailsTable
            reservations={filteredReservations}
            dateKey={'activityArrivalDate'}
            isArrivalDetails={true}
            spotId={selectedSpot._id}
            date={filterDay}
          />
          &nbsp;
          <Paper>
            <Box display={'flex'} flexDirection={'column'} p={2}>
              <DomainsFilter
                domains={domains}
                includingDomainIds={includingDomainIds}
                onChange={setIncludingDomainIds}
              />
              &nbsp;
              <KindFilter
                includingKinds={includingKinds}
                onChange={setIncludingKinds}
              />
              &nbsp;
              <StatusFilter
                includingStatus={includingStatus}
                onChange={setIncludingStatus}
              />
              &nbsp;
              <SpotMultiTimingsFilter
                timings={selectedSpot!.arrivals!}
                includingOccurrences={includingSpotOccurrences}
                onChange={changeIncludingSpotOccurrences}
              />
              &nbsp;
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                type="submit"
                disabled={filteredReservations.length === 0}
                onClick={printArrivalList}
              >
                Imprimer la liste
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      <TimeSlotsDialog
        timeSlotReservations={selectedTimeSlotReservations}
        activityName={selectedTimeSlotReservations[0]?.activity?.displayName || ''}
        activityDate={selectedTimeSlotReservations[0]?.activityDate || Date()}
        dialogOpen={timeSlotReservationsViewDialogOpen}
        dialogClose={timeSlotReservationsViewDialogClose}
        onUpdate={() => {
          timeSlotReservationsViewDialog(
            selectedTimeSlotReservations[0]?.activity._id, 
            selectedTimeSlotReservations[0]?.activityTimeSlot,
            true
            );
            refreshReservations();
        }}
      />
    </div>
  );
}
