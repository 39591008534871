import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { ViewAvailableBusesDialog } from '../../../components/dialogs/bus/viewAvailableBusesDialog.component';
import { useDomainPath } from '../../../auth/auth.context';
import { countBookingPeople, getReservationStatusFromBusStatus } from '@crm/utils';
import { put } from '../../../fetch';
import { useAlerts } from '../../../hooks/useAlerts';
import { useReservation } from '../reservation.context';

interface Props {
  busesFilled: CRM.FilledBusTravel[];
  reservation: Partial<CRM.ReservationDocument>;
  spotTimingOccurence:CRM.SpotTimingsOccurence;
  spotId: string;
  allSpots: CRM.ActivitySpotDocument[];
  direction: CRM.BusTravelDirection;
  onUpdate: () => void;
  actionButton?: boolean;
  setActionButton?:React.Dispatch<React.SetStateAction<boolean>>;
}


export const CheckBusReplace: React.FC<Props> = ({
  busesFilled,
  reservation,
  spotTimingOccurence,
  spotId,
  allSpots,
  direction,
  onUpdate,
  actionButton,
  setActionButton,
}) => {
  const { update } = useReservation();
  const [availableBusesDialogOpen, setAvailableBusesDialogOpen] = useState(false);
  //const { userGroup } = useAuth();
  const [availableBusTravels, setAvailableBusTravels] = useState<CRM.FilledBusTravel[]>([]);
  const reservationPath = useDomainPath('/reservation');
  const { notify } = useAlerts();
  
  const availableBusesDialog = useCallback(() => {
    const check: string | undefined = direction === "departure" ? reservation.checkInBusId : reservation.checkOutBusId;
    console.log(reservation.checkInBusId,busesFilled)
    const availableBusTravels: CRM.FilledBusTravel[] = check ? busesFilled.filter(
      b => !b.bus._id.includes(check)
        )/*.map( b =>
      filledBusTravelToDetailed(
        b, {direction: direction, allowedDomains: userGroup ? [userGroup?.domainId] :[]}
      )
    )*/ : busesFilled;
    setAvailableBusTravels(availableBusTravels);
    setAvailableBusesDialogOpen(true);
    },[
      reservation.checkInBusId,
      reservation.checkOutBusId,
      busesFilled,
      //userGroup,
      direction,
      setAvailableBusesDialogOpen
    ]);
    const availableBusesDialogClose = useCallback(
      () => setAvailableBusesDialogOpen(false),
      [setAvailableBusesDialogOpen],
  );

  useEffect(() => {
    if(actionButton === true && setActionButton) {
      availableBusesDialog();
      setActionButton(!actionButton)
    } 
  }, [actionButton,setActionButton, availableBusesDialog]);

  const updateBusDeparture = useCallback((busTravelId: string) => {
    //#TODO verify if passengers < bus capacity
    const busFilled: CRM.FilledBusTravel | undefined = busesFilled.find(bt => bt._id.includes(busTravelId));
    const totalPassengers: number = ( 
        reservation.people && 
        (countBookingPeople(reservation.people) + (busFilled?.passengersCount || 0))
      ) || 0;
    if(!busFilled) {
      return;
    }
    let checkStatus = getReservationStatusFromBusStatus(
      busFilled.bus, 
      direction, 
      spotId,
      spotTimingOccurence
      ) || 
      reservation.status;
    checkStatus = (
      checkStatus === "started" && reservation.status === "booked"
      ) ? "booked" : checkStatus;

    if(!reservation._id) {
      if(direction === "departure") { 
        update({
          checkInBusId: busFilled.bus._id, 
          checkInBusIdAutoFill: false,
          status: checkStatus
        });
        if(totalPassengers >
          (busFilled.bus.passengerCapacity || 0)) {
            notify('Bus attribué avec capacité dépassée ! ( +' +
              (totalPassengers-(busFilled?.bus.passengerCapacity || 0)) +
              ' personnes )', 'warning'
            );
        } else {
          notify('Le bus a bien été attribué','success');
        }
        availableBusesDialogClose();
      }
      return;
    }
      //#TODO call replace bus for reservation
      try {
        if(direction === "departure") {
          reservation.checkInBusId = busFilled.bus._id;
          reservation.checkInBusIdAutoFill = false;
        } else {
          reservation.checkOutBusId = busFilled.bus._id;
        }
        reservation.status = checkStatus;
          
      put(
        reservationPath+'/'+reservation._id, 
        reservation as Partial<CRM.Reservation>
      ).then(() => {
        if(totalPassengers >
          (busFilled.bus.passengerCapacity || 0)) {
            notify('Bus attribué avec capacité dépassée ! ( +' +
              (totalPassengers-(busFilled?.bus.passengerCapacity || 0)) +
              ' personnes )', 'warning'
            );
        } else {
          notify('Le bus a bien été attribué','success');
        }
        //refresh reservation 
        onUpdate();
        availableBusesDialogClose();
      });
      } catch(error) {
        notify('Une erreur s\'est produite, le remplacement n\'a pas eu lieu','error');
      }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    busesFilled,
    notify,
    onUpdate,
    reservation,
    reservationPath,
    direction,
    availableBusesDialogClose,
    spotTimingOccurence,
    spotId
  ]);

  return (
    <div>
      {setActionButton === undefined && 
          <Button
          variant="contained"
          color="secondary"
          size="medium"
          onClick={availableBusesDialog}
        >Changer de Bus</Button>
      }
      {
        spotTimingOccurence && (
          <ViewAvailableBusesDialog
            availableBusTravels={availableBusTravels}
            dialogOpen={availableBusesDialogOpen}
            onSelect={(busTravelId) => {
              updateBusDeparture(busTravelId);
            }}
            dialogClose={availableBusesDialogClose}
            isReplacement={"replace"}
            spotTimingOccurence={spotTimingOccurence}
            spots={allSpots}
          />
        )
      }
    </div>
  );
}