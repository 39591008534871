import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { get, post, put } from '../../fetch';
import { Dayjs } from 'dayjs';
import { useAuth, useDomainPath } from '../../auth/auth.context';
import { Box, Card, CardContent, Chip, Grid, Radio, RadioGroup, Theme, Typography, makeStyles } from '@material-ui/core';
import { ViewPassengersDialog } from '../dialogs/bus/viewPassengersDialog.component';
import { useAlerts } from '../../hooks/useAlerts';
import { AlertDialog } from '../alerts/AlertDialog.component';
import { ViewAvailableBusesDialog } from '../dialogs/bus/viewAvailableBusesDialog.component';
import { countBookingPeople, fixedDayjs } from '@crm/utils';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme';
import { BusStatusLabel } from '../labels/busStatusLabel.component';


const useStyles = makeStyles<DefaultTheme>(
  (theme: Theme) => ({
  root: {
    marginBottom: "0.5em",
    [theme.breakpoints.down('md')]: {
      minWidth: 200
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 275
    },
  },
  line: {
    margin: 0,
    fontSize: 12,
    textOverflow: "ellipsis",
    [theme.breakpoints.down('md')]: {
      whiteSpace: "break-spaces",
    },
    [theme.breakpoints.up('md')]: {
      whiteSpace: "nowrap",
    },
  },
  label: {
    margin: 0,
    textAlign: "right"
  },
  chip: {
    margin: 2
  },
  chipContainer: {
    marginTop: "0.5em"
  }

}));

type BusPrioritySelectProps = {
  spot: CRM.ActivitySpotDocument;
  day: Dayjs;
  direction: CRM.BusTravelDirection;
  refresh: number;
  spotTimingOccurence?: CRM.SpotTimingsOccurence;
  onlyBusesAlreadyDeparted?: boolean;
  forcePriorityBusTravel?: CRM.BusTravelPriorityParams;
  setForcePriorityBusTravel?: React.Dispatch<React.SetStateAction<CRM.BusTravelPriorityParams>>;
  setValidateBusDepartureAction?: React.Dispatch<React.SetStateAction<boolean>>;
  validateBusDepartureAction?: boolean;
  forDriverId?: string;
  onUpdate?: () => void;
};

export const BusPrioritySelect = ({
  spot,
  day,
  direction,
  refresh,
  spotTimingOccurence,
  onlyBusesAlreadyDeparted,
  forcePriorityBusTravel,
  setForcePriorityBusTravel,
  setValidateBusDepartureAction,
  validateBusDepartureAction,
  forDriverId,
  onUpdate
}: BusPrioritySelectProps) => {
  const classes = useStyles();
  const { notify } = useAlerts();
  const { userGroup } = useAuth();
  const [busesFilled, setBusesFilled] = useState<CRM.FilledBusTravel[]>([]);
  const queryDay = 'day='+day.format('YYYYMMDD');
  const querySpot = '&spotId='+spot._id;
  const querySpotHour = '&spotHour='+spotTimingOccurence?.hour;
  const querySpotMinutes = '&spotMinutes='+spotTimingOccurence?.minute;
  const queryDirection = "&direction="+direction;
  const queryRealTime = onlyBusesAlreadyDeparted === true ? "&realTime=false" : (onlyBusesAlreadyDeparted === false ? "&realTime=true" :"");
  const queryForDriverId = forDriverId ? "&forDriverId="+forDriverId : "";
  const reservationPath = useDomainPath('/reservation');
  const busTravelPath = useDomainPath('/busTravel');
  const busPath = useDomainPath('/bus');
  const [domains, setDomains] = useState<CRM.Dictionary<string>>({});
  const [viewPassengersReservations, setViewPassengersReservations] = useState<CRM.ReservationDetailed[]>([]);
  const [viewPassengersDialogOpen, setViewPassengersDialogOpen] = useState(false);
  const [validateBusDepartureDialogOpen, setValidateBusDepartureDialogOpen] = useState(false);
  const [transportsAreFullDialogOpen, setTransportsAreFullDialogOpen] = useState(false);
  const [availableBusesDialogOpen, setAvailableBusesDialogOpen] = useState(false);
  const [busAlreadyDepartedDialogOpen, setBusAlreadyDepartedDialogOpen] = useState(false);
  const [selectedBusFilled, setSelectedBusFilled] = useState<CRM.FilledBusTravel | undefined>(undefined);
  const [selectedBusPassengerGroupsCount, setSelectedBusPassengerGroupsCount] = useState<number[]>([]);
  const [availableBusTravels, setAvailableBusTravels] = useState<CRM.FilledBusTravel[]>([]);
  const [busAddOrReplaceClick, setBusAddOrReplaceClick] = 
    useState<Exclude<CRM.VehicleGridType, 'admin'>>('replace');
  const spotsPath = useDomainPath('/activity-spot');
  const [spots, setSpots] = useState<CRM.ActivitySpotDocument[]>([]);

  useEffect(() => {
    get<CRM.ActivitySpotDocument[]>(spotsPath).then(setSpots);
  }, [setSpots, spotsPath]);

  const validateBusDepartureDialog = useCallback(() => {
    setValidateBusDepartureDialogOpen(true);
  },[setValidateBusDepartureDialogOpen]);
  const validateBusDepartureDialogClose = useCallback(
    () => setValidateBusDepartureDialogOpen(false),
    [setValidateBusDepartureDialogOpen],
  );
  const transportsAreFullDialog = useCallback(() => {
    setTransportsAreFullDialogOpen(true);
  },[setTransportsAreFullDialogOpen]);
  const transportsAreFullDialogClose = useCallback(
    () => setTransportsAreFullDialogOpen(false),
    [setTransportsAreFullDialogOpen],
  );
  const busAlreadyDepartedDialog = useCallback(() => {
    setBusAlreadyDepartedDialogOpen(true);
  },[setBusAlreadyDepartedDialogOpen]);
  const busAlreadyDepartedDialogClose = useCallback(
    () => setBusAlreadyDepartedDialogOpen(false),
    [setBusAlreadyDepartedDialogOpen],
  );


  const viewPassengersDialog = useCallback((busFilled: CRM.FilledBusTravel) => {
    get<CRM.ReservationDetailed[]>(
      `${reservationPath}/travelReservations/filledOrEmptyBusReservations?`+
      'busId='+busFilled.bus._id +"&" +
      queryDay +
      querySpot +
      querySpotHour +
      querySpotMinutes +
      queryDirection +
      queryRealTime).then((reservations) => {
        if (onlyBusesAlreadyDeparted === true) {
          setViewPassengersReservations(reservations.filter(r => r.status === "in-activity"));
        } else {
          setViewPassengersReservations(reservations);
        }
      setSelectedBusFilled(busFilled);
      setViewPassengersDialogOpen(true);
    }
    )
  }
    , [
      setViewPassengersReservations,
      setViewPassengersDialogOpen,
      onlyBusesAlreadyDeparted,
      reservationPath, 
      queryDay, 
      querySpot, 
      querySpotHour, 
      querySpotMinutes, 
      queryDirection,
      queryRealTime
    ]
  );
  const viewPassengersDialogClose = useCallback(
    () => setViewPassengersDialogOpen(false),
    [setViewPassengersDialogOpen],
  );

  const availableBusesDialog = useCallback((selectedBusFilledBusId?: string) => {
    get<CRM.FilledBusTravel[]>(
      `${reservationPath +
      '/travelReservations/availableBusesForSpotTiming?'+ 
      queryDay + 
      querySpot + 
      querySpotHour + 
      querySpotMinutes +
      queryDirection +
      "&busToReplaceId="+selectedBusFilledBusId
      }`
    ).then(
      _busTravels => {
        if (!_busTravels) {
          return;
        }
        selectedBusFilledBusId && get<CRM.ReservationDetailed[]>(
          `${reservationPath}/travelReservations/filledOrEmptyBusReservations?`+
          'busId='+selectedBusFilledBusId +"&" +
          queryDay +
          querySpot +
          querySpotHour +
          querySpotMinutes +
          queryDirection).then((reservations) => {
          const groupsCount: number[] = [];
          for(const reservation of reservations) {
            groupsCount.push(countBookingPeople(reservation.people));
          }
          setSelectedBusPassengerGroupsCount(groupsCount);
          });
        setAvailableBusTravels(_busTravels);
      });
    setAvailableBusesDialogOpen(true);
  }, [
    setAvailableBusesDialogOpen,
    reservationPath, 
    queryDay, 
    querySpot, 
    querySpotHour, 
    querySpotMinutes,
    queryDirection
  ]);
  const availableBusesDialogClose = useCallback(
    () => setAvailableBusesDialogOpen(false),
    [setAvailableBusesDialogOpen],
  );

  useEffect(() => {
    get<CRM.EntityAbbreviation[]>(`domains/abbreviations`).then(domains => {
      setDomains(
        domains.reduce((acc, domain) => {
          acc[domain._id] = domain.abbreviation;
          return acc;
        }, {} as CRM.Dictionary<string>),
      );
    });
  }, [setDomains]); 

  const refreshBusesFilled = useCallback(() =>{
    if (spotTimingOccurence) {
      get<CRM.FilledBusTravel[]>(reservationPath + `/travelReservations/filledBusesTravels?` +
        queryDay +
        querySpot +
        querySpotHour +
        querySpotMinutes +
        queryDirection + 
        queryRealTime +
        queryForDriverId)
        .then((buses) => {
          setBusesFilled(buses.sort().sort((a, b) =>
            ((b.bus.passengerCapacity - b.passengersCount) < (a.bus.passengerCapacity - a.passengersCount) ? -1 : 1)
          ));
        }
        );
    }
  }, [
    setBusesFilled, 
    reservationPath, 
    queryDay, 
    querySpot, 
    querySpotHour, 
    querySpotMinutes, 
    queryDirection,
    queryRealTime,
    spotTimingOccurence,
    queryForDriverId
  ]);

  useEffect(() => {
    if(refresh) {
      refreshBusesFilled();
    }
  },[
    refreshBusesFilled, refresh
  ]);

  const validateBusDeparture = useCallback((action: CRM.BusDepartureAction, bus?: CRM.BusDocument | undefined) => {
    if(!bus) {
      return;
    }
    const uriBusDeparture = action === "validate" ? "validateBusDeparture" : "cancelBusDeparture";
    const validateOrCancelMsg = action === "validate" ? "validé" : "annulé";
    try {
      post<CRM.BusTravelFindParams>(
        reservationPath+'/travelReservations/'+uriBusDeparture, 
      {
        busId: bus._id,
        day: day.format('YYYYMMDD'),
        spotId: spot._id,
        spotHour: spotTimingOccurence?.hour.toString(),
        spotMinutes: spotTimingOccurence?.minute.toString(),
        direction: direction
      } as CRM.BusTravelFindParams
      ).then(() => {
        notify(`Départ `+validateOrCancelMsg+` pour le bus `+bus.name, 'success');
        confirmPreviousArrivals(bus);
        //refreshBusesFilled();
        if(setForcePriorityBusTravel && forcePriorityBusTravel) {
          setForcePriorityBusTravel({...forcePriorityBusTravel, busStatus: action === "validate" ? "in-rotation" : "ready"})
        }
        onUpdate && onUpdate();
        action === "validate" && validateBusDepartureDialogClose();
      });
    } catch (err) {
      notify(`Impossible de valider / d'annuler le départ du bus `+bus.name, 'error');
      action === "validate" && validateBusDepartureDialogClose();
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    reservationPath,
    day,
    direction,
    spotTimingOccurence?.hour,
    spotTimingOccurence?.minute,
    spot._id,
    onUpdate,
    notify,
    //refreshBusesFilled,
    validateBusDepartureDialogClose
  ]);

  const updatePriority = useCallback(
    (event: ChangeEvent<HTMLInputElement> | undefined) => {
      const busFilledId = event?.target.value;
      if (busFilledId) {
        /*busesFilled.map((busfilled, index) => {
          if(busesFilled[index]._id.includes(busFilledId)) {
            busesFilled[index].fillPriority = 1;
          } else {
            busesFilled[index].fillPriority = 0;
          }
          return true;
        });*/
        if(setForcePriorityBusTravel) {
          const busTravel = busesFilled.find(bt => bt._id.includes(busFilledId));
          setForcePriorityBusTravel({ 
            busTravelId: busFilledId, 
            busId: busTravel?.bus._id || '',
            busName: busTravel?.bus.name || '',
            hour: spotTimingOccurence?.hour || 0, 
            minute: spotTimingOccurence?.minute || 0,
            inRotationTime: busTravel?.bus.inRotationTime || undefined,
            busStatus: busTravel?.bus.status || 'ready'
          });
          onUpdate && onUpdate();
          return;
        }
        
        const busId: string | undefined = busesFilled.find(b => b._id === busFilledId)?.bus._id || undefined;
        if(busId) {
          const params: CRM.BusPriorityParams = {
            spotId: spot._id,
            timing: {hour: spotTimingOccurence?.hour || 0, minute: spotTimingOccurence?.minute || 0},
            busId: busId,
            direction: direction
          };
          put(busTravelPath+'/priority', params).then(() => {refreshBusesFilled(); onUpdate && onUpdate();});
        }
        //put(busTravelPath+'/priority', busesFilled).then(refreshBusesFilled);
      }
    },
    [
      busesFilled, 
      busTravelPath, 
      refreshBusesFilled, 
      direction, 
      onUpdate, 
      setForcePriorityBusTravel, 
      spot._id, 
      spotTimingOccurence
    ],
  );

  const replaceBusTravel = useCallback((
    busTravelId: string, 
    spotTimingOccurence: CRM.SpotTimingsOccurence,
    busTravelIdToReplace: string,
  ) => {
    const params: CRM.PutReplaceTravelSpotTimingsParams = {
      spotId: spot._id,
      busTravelId: busTravelId,
      spotTimingOccurence: spotTimingOccurence,
      busTravelIdToReplace: busTravelIdToReplace,
      day: day.format('YYYYMMDD')
    };
    const bustravel = availableBusTravels.find(b => b._id.valueOf() === busTravelId);
    const busTravelToReplace = busesFilled.find(b=> b._id.valueOf() === busTravelIdToReplace);
    //let warningCapacity: boolean = false;
    if(bustravel?.bus.passengerCapacity && busTravelToReplace?.passengersCount
      && bustravel.bus.passengerCapacity < busTravelToReplace?.passengersCount) {
        // show warning dialog
        //warningCapacity = true;
      }
    try {
      post(reservationPath+'/travelReservations/replaceSpotTiming', params).then((_isSuccess: string) => {
        const isSuccess: boolean | null = _isSuccess === 'true' ? 
          true : _isSuccess === 'false' ? 
          false : null;
        if(isSuccess === true) {
          //if(!warningCapacity) {
            notify('Le bus a bien été remplacé', 'success');
          /*} else {
            notify('Bus remplacé avec capacité dépassée !', 'warning');
          }*/
        } else if(isSuccess === false) {
          //show dialog (add transport then try replacing another time)
          transportsAreFullDialog();
        } else {
          notify('Impossible de Remplacer le bus, trajet introuvable pour ce bus', 'error');
        }
        refreshBusesFilled();
        availableBusesDialogClose();
      });
    } catch {
      notify('Impossible de Remplacer le bus', 'error');
    }
    // #TODO fix eventually these dependancies for not causes infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    reservationPath,
    availableBusTravels,
    notify,
    availableBusesDialogClose,
    refreshBusesFilled,
    spot._id,
    day
  ]);
  
  const addSpotTimingToBusTravel = useCallback((
    busTravelId: string, 
    spotTimingOccurence: CRM.SpotTimingsOccurence, 
    ) => {
    const params: CRM.PutTravelSpotTimingParams = {
      spotId: spot._id,
      busTravelId: busTravelId,
      spotTimingOccurence: spotTimingOccurence,
      isRemove: false
    };
    try {
      put(busTravelPath+'/addOrRemoveSpotTiming', params).then(() => {
        refreshBusesFilled();
        availableBusesDialogClose();
        notify('Le bus a bien été ajouté', 'success');
      });
    } catch {
      notify('Impossible d\'Ajouter/Remplacer le bus', 'error');
    }
  },[
    busTravelPath, 
    refreshBusesFilled, 
    spot._id, 
    notify, 
    availableBusesDialogClose 
  ]);

  useEffect(() => {
    if(
      validateBusDepartureAction && 
      validateBusDepartureAction === true && 
      setValidateBusDepartureAction &&
      forcePriorityBusTravel
    ) {
      setSelectedBusFilled(busesFilled.find(
        b => b._id.includes(forcePriorityBusTravel.busTravelId))
      );
      validateBusDepartureDialog();
      setValidateBusDepartureAction(!validateBusDepartureAction);
    }
  },[
    validateBusDepartureAction,
    setValidateBusDepartureAction,
    validateBusDepartureDialog,
    forcePriorityBusTravel,
    busesFilled
  ]);

  const confirmBusArrival = useCallback((bus: CRM.Bus, optHour?: number, optMinute?: number, optStatus?: CRM.BusStatus) => {
    bus.status = optStatus || "ready";
    bus.inRotationTime = { hour: 0, minute: 0};
    const today = fixedDayjs(Date());
    const newBusArrived: CRM.BusTravelChange = {
      spotId: spot._id,
      hour: optHour || spotTimingOccurence?.hour || 0,
      minute: optMinute || spotTimingOccurence?.minute || 0,
      direction: direction
    }
    const todayNewBusArrived:CRM.TodayBusArrived = {today: today.toDate(), busArrived: [newBusArrived]};

    bus.todayBusArrived = (
      (bus.todayBusArrived && !fixedDayjs(bus.todayBusArrived.today).isSame(today)) || !bus.todayBusArrived
    ) ? todayNewBusArrived : {
      ...bus.todayBusArrived, 
      busArrived: bus.todayBusArrived.busArrived.concat(newBusArrived)
    }

    put(
      busPath+'/'+bus._id, 
      bus
    ).then(() => {
      /*if(direction === "return") {
        
        //CALL WITH A CRON AT MIDNIGHT
        //#TODO update reservations status for daytwo
        const hour = optHour || spotTimingOccurence?.hour || 0;
        const minutes = optMinute || spotTimingOccurence?.minute || 0;
        const params: CRM.BusTravelReturnParams = {
          busId: bus._id,
          spotId: spot._id,
          day: day.format('YYYYMMDD'),
          spotHour: String(hour),
          spotMinutes: String(minutes)
        };
        try {
          put(reservationPath+'/travelReservations/updateStatusForReturn', params).then(() => {
            refreshBusesFilled();
            onUpdate && onUpdate();
          });
        } catch {
          console.log('error while updateStatusForReturn')
        }
      } else {*/

        refreshBusesFilled();
        onUpdate && onUpdate();
      //}
    });
  },[
    busPath,
    direction,
    onUpdate,
    refreshBusesFilled,
    spot._id,
    spotTimingOccurence
  ]);

  const confirmPreviousArrivals = useCallback((bus: CRM.Bus) => {
    const today = fixedDayjs(Date());
    let previousDepartures: CRM.BusTravelChange[] = [];
    /*console.log(bus.todayBusDeparted)
    console.log(today.hour(spotTimingOccurence?.hour || 0)
    .minute(spotTimingOccurence?.minute || 0))
    console.log(bus.todayBusDeparted && fixedDayjs(bus.todayBusDeparted.today).isSame(today, 'day'))*/

     if(bus.todayBusDeparted && fixedDayjs(bus.todayBusDeparted.today).isSame(today, 'day')) {
      previousDepartures = bus.todayBusDeparted.busDeparted.filter(
        bd => bd.spotId === spot._id &&
        bd.direction === direction && 
        today.hour(bd.hour).minute(bd.minute).isBefore(
          today.hour(spotTimingOccurence?.hour || 0)
          .minute(spotTimingOccurence?.minute || 0)
        )
      );
      bus.todayBusDeparted = undefined; // do not update busDetparted one second time
      for(const departure of previousDepartures) {
        confirmBusArrival(bus, departure.hour, departure.minute, "in-rotation");
      }
     }
  },[
    spot._id,
    direction,
    spotTimingOccurence,
    confirmBusArrival
  ]);

  return (
    <Box>
      <RadioGroup 
        aria-label="Bus Priority"
        //value={busesFilled.find(b => b.fillPriority === 1)?._id || ''}
        value={forcePriorityBusTravel !== undefined ? 
            (forcePriorityBusTravel.hour === (spotTimingOccurence?.hour || 0) && 
            forcePriorityBusTravel.minute === (spotTimingOccurence?.minute || 0) ? 
            forcePriorityBusTravel.busTravelId : '') : 
          busesFilled.find(
          b => b.travelSpotsTimings.find(
            ts => ts.spotId === spot._id && ts.timings.find(
              t=> t.hour === (spotTimingOccurence?.hour || 0) &&
                  t.minute === (spotTimingOccurence?.minute || 0)
              )?.busTravelFilling?.find(
                bf => bf.domainId === (userGroup?.domainId || "") && bf.fillingPriority === 1
              )?.fillingPriority
            )
          )?._id || ''}
        onChange={updatePriority}
        style={{ width: '100%', display: 'inline', alignItems: 'center', marginTop: '1em' }}
      >
        {
        <Typography>
          <b>
          {onlyBusesAlreadyDeparted === true ? "En rotation" : "Transport"}
          {
            direction === "return" && spotTimingOccurence && " "+
              spotTimingOccurence?.hour+"H"+ 
              spotTimingOccurence?.minute + 
              (spotTimingOccurence?.minute === 0 ? '0' : '')
          }
          </b>
        </Typography>
        }
        {busesFilled?.map((busFilled) => {
          /*const isNoBusRotation: boolean = (
            !busFilled.bus.inRotationTime || (
              busFilled.bus.inRotationTime && 
                spotTimingOccurence?.hour+''+spotTimingOccurence?.minute !== 
                  busFilled.bus.inRotationTime.hour+''+busFilled.bus.inRotationTime.minute
          ));*/
          /*const isBusRotationOtherTime: boolean = busFilled.bus.status === "in-rotation" && 
            busFilled.bus.inRotationTime && 
            spotTimingOccurence?.hour+''+spotTimingOccurence?.minute !== 
            busFilled.bus.inRotationTime.hour+''+busFilled.bus.inRotationTime.minute ? 
            true : false;*/
          /*let hourRotation: string = "";
          if(isBusRotationOtherTime && busFilled.bus.inRotationTime) {
            const minute = busFilled.bus.inRotationTime.minute === 0 ? "00" : ""+busFilled.bus.inRotationTime.minute;
            hourRotation = busFilled.bus.inRotationTime.hour+'h'+minute;
          }*/

          const today = fixedDayjs(Date());
          const isNoBusRotation: boolean = busFilled.bus.todayBusDeparted && 
          fixedDayjs(busFilled.bus.todayBusDeparted.today).isSame(today, 'day') && 
          busFilled.bus.todayBusDeparted.busDeparted.find(
            ba => ba.direction === direction && 
            ba.spotId === spot._id && 
            ba.hour === (spotTimingOccurence?.hour || 0) &&
            ba.minute === (spotTimingOccurence?.minute || 0)
          ) ? false : true;

          let isBusArrived: boolean = busFilled.bus.todayBusArrived && 
            fixedDayjs(busFilled.bus.todayBusArrived.today).isSame(today, 'day') && 
            busFilled.bus.todayBusArrived.busArrived.find(
              ba => ba.direction === direction && 
              ba.spotId === spot._id && 
              ba.hour === (spotTimingOccurence?.hour || 0) &&
              ba.minute === (spotTimingOccurence?.minute || 0)
            ) ? true : false;

          return (
            <Card className={classes.root} variant='outlined'>
              <CardContent>
                <Typography className={classes.line}>
                  {!onlyBusesAlreadyDeparted && !isBusArrived &&
                    <Radio 
                      color="secondary" 
                      value={busFilled._id} 
                      onClick={() => { !isNoBusRotation && !forDriverId && busAlreadyDepartedDialog();}}
                    />
                  }
                  <b>{busFilled.bus.name}</b>&nbsp;|&nbsp;
                  {busFilled.driverName}&nbsp;|&nbsp;
                  {!isBusArrived && (busFilled.bus.passengerCapacity - busFilled.passengersCount) + " places restantes"}
                  {isBusArrived && <b>ARRIVÉ</b>}
                </Typography>

                { direction === "return" && 
                  <Typography className={classes.label}>{BusStatusLabel(busFilled.bus.status)}</Typography> 
                }
                <Grid container xs={12} className={classes.line}>
                  {busFilled.passengersByDomain.map((passengersByDomain) => {
                    return (
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                      {domains[passengersByDomain.domainId]} : {passengersByDomain.passengersCount}
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container xs={12} className={classes.chipContainer}>
                  {!onlyBusesAlreadyDeparted && 
                    <Chip
                      onClick={() => {viewPassengersDialog(busFilled);}}
                      className={classes.chip}
                      label="Voir Liste"
                      component="a"
                      clickable
                    />
                  }
                  {!onlyBusesAlreadyDeparted && isNoBusRotation && !isBusArrived &&
                    <Chip
                      onClick={() => {
                        setSelectedBusFilled(busFilled);
                        setBusAddOrReplaceClick('replace');
                        availableBusesDialog(busFilled.bus._id);
                      }}
                      className={classes.chip}
                      label="Remplacer le véhicule"
                      component="a"
                      clickable
                    />
                  }
                  {!onlyBusesAlreadyDeparted && isNoBusRotation && !forDriverId && !isBusArrived && 
                    <Chip 
                      onClick={() => { 
                        /*if(isBusRotationOtherTime) {
                          notify('Ce bus est actuellement en rotation sur un autre créneau','warning')
                          return;
                        }*/
                        setSelectedBusFilled(busFilled);
                        validateBusDepartureDialog();/*validateBusDeparture(busFilled.bus);*/ 
                      }} 
                      className={classes.chip} 
                      label={
                        /*isBusRotationOtherTime ? "Parti à ("+hourRotation+")" :*/ "Confirmer le départ"
                      }
                      component="a" 
                      clickable 
                    />
                  }
                  { (onlyBusesAlreadyDeparted || !isNoBusRotation) && !isBusArrived && //#TODO revert confirm departure, all reservations come back to location
                    <Chip 
                      onClick={() => { 
                        setSelectedBusFilled(busFilled);
                        validateBusDeparture("cancel",busFilled.bus);
                      }} 
                      className={classes.chip} 
                      label="Annuler le départ" 
                      component="a" 
                      clickable 
                    />
                  }
                  { !isNoBusRotation && !isBusArrived &&
                    <Chip 
                      onClick={() => { 
                        setSelectedBusFilled(busFilled);
                        confirmBusArrival(busFilled.bus);
                      }} 
                      className={classes.chip} 
                      label="Confirmer l'arrivée" 
                      component="a" 
                      clickable 
                    />
                  }
                </Grid>
              </CardContent>
            </Card>
          );
        })}
        {!onlyBusesAlreadyDeparted && direction !== "return" && !forDriverId &&
          <Grid container xs={12}>
            <Chip 
              onClick={() => {
                setBusAddOrReplaceClick('add');
                availableBusesDialog();
              }}
              label="Ajouter un transport" 
              component="a"  
              clickable 
            />
          </Grid>
        }
      </RadioGroup>
      {spotTimingOccurence && (
      <ViewPassengersDialog
        reservations={viewPassengersReservations}
        busName={selectedBusFilled?.bus.name || ''}
        driverName={selectedBusFilled?.driverName || 'Non renseigné'}
        dialogOpen={viewPassengersDialogOpen}
        domains={domains}
        spot={spot}
        includingSpotOccurrence={spotTimingOccurence}
        dialogClose={viewPassengersDialogClose}
        allSpots={spots}
        direction={direction}
        busesFilled={busesFilled}
        onUpdate={() => {
          selectedBusFilled && viewPassengersDialog(selectedBusFilled);
          refreshBusesFilled();
          onUpdate && onUpdate();
        }
        }
      />)}
      {spotTimingOccurence && (
      <ViewAvailableBusesDialog
        availableBusTravels={availableBusTravels}
        dialogOpen={availableBusesDialogOpen}
        onSelect={(busTravelId) => { 
          //console.log(busTravelId+' is selected');
          if(busAddOrReplaceClick === "add") {
            addSpotTimingToBusTravel(busTravelId, spotTimingOccurence);
          } else {
            //#TODO call replace bus for travelReservations
            selectedBusFilled &&
            replaceBusTravel(busTravelId, spotTimingOccurence, selectedBusFilled._id);     
          }
        }}
        dialogClose={availableBusesDialogClose}
        isReplacement={busAddOrReplaceClick}
        spotTimingOccurence={spotTimingOccurence}
        spots={spots}
        busToReplacePassengers={selectedBusPassengerGroupsCount || []}
      />
      )}
      <AlertDialog
          accept={()=>{validateBusDeparture("validate",selectedBusFilled?.bus);}}
          cancel={validateBusDepartureDialogClose}
          open={validateBusDepartureDialogOpen}
          title={
            'Confirmer le départ pour '+ 
            selectedBusFilled?.bus.name+((
              (selectedBusFilled?.bus.passengerCapacity || 0) - (selectedBusFilled?.passengersCount || 0)
            ) < 0 ? "\n\r! ATTENTION: CAPACITE DEPASSEE !" : "")
          }
          content={
            "Etes-vous sûr de vouloir valider le départ pour ce bus ?"
          }
      />
      <AlertDialog
          accept={()=>{transportsAreFullDialogClose(); setBusAddOrReplaceClick("add"); availableBusesDialog();}}
          cancel={transportsAreFullDialogClose}
          open={transportsAreFullDialogOpen}
          title={'Tous les bus sont complets'}
          content={
            "Il n'y a pas assez de places disponibles dans les bus attribués sur " +
            "ce créneau,\n\n veuillez ajouter un transport puis réessayez."
          }
      />
      <AlertDialog
          accept={() => {busAlreadyDepartedDialogClose();}}
          open={busAlreadyDepartedDialogOpen}
          title={'Vous avez sélectionné un bus déjà parti'}
          content={
            "ATTENTION! Vous êtes sur le point d'ajouter des passagers à un bus déjà parti"
          }
      />
    </Box>
  );
};
